import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-registration-suspended-modal',
  templateUrl: './registration-suspended-modal.component.html',
  styleUrls: ['./registration-suspended-modal.component.scss'],
})
export class RegistrationSuspendedModalComponent {
  constructor(public dialogRef: MatDialogRef<RegistrationSuspendedModalComponent>) {}

  public openTerms(type: string) {
    const link = type == 'survey' ? 'https://shrt.ednipro.dp.ua/yy48T1OP3' : 'https://t.me/ednipro_help';
    window.open(link, '_blank');
  }
}
